import React, { useState } from "react"

import Layout from "layout/Layout"
import Message from "elements/Message"
import Container from "layout/Container"
import FeedbackForm from "./FeedbackForm"
import PersonalDetails from "./PersonalDetails"

const PERSONAL_DETAILS_VIEW = "PERSONAL_DETAILS_VIEW"
const FEEDBACK_FORM_VIEW = "FEEDBACK_FORM_VIEW"

const FeedbackView = ({ view, setView, pageContext }) => {
  switch (view) {
    case FEEDBACK_FORM_VIEW:
      return <FeedbackForm setView={setView} pageContext={pageContext} />
    case PERSONAL_DETAILS_VIEW:
    default:
      return <PersonalDetails setView={setView} pageContext={pageContext} />
  }
}

const Feedback = ({ pageContext }) => {
  const [view, setView] = useState(PERSONAL_DETAILS_VIEW)

  return (
    <Layout
      isPrivate
      pageContext={pageContext}
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      display={{ footer: false, helpCenterBanner: false }}
    >
      <Container isCentered>
        <FeedbackView view={view} setView={setView} pageContext={pageContext} />
      </Container>
    </Layout>
  )
}

export default Feedback
