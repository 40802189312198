import React, { Fragment, useState, useContext } from "react"
import { Formik, Form } from "formik"

import Message from "elements/Message"
import ActionButtons from "elements/ActionButtons"
import { personalDetailsMessages } from "./utils/messages"

import { AppContext } from "../../context/AppContext"
import { generateInitialValues } from "../../services/context"
import { generateFormField } from "elements/Form/services/form"
import { useMentalHealthFormFields } from "../Enrollment/hooks/useAirtableMHFormFields"

import { getSignedInUser } from "../Auth/services/user"

const FEEDBACK_FORM_VIEW = "FEEDBACK_FORM_VIEW"

const PersonalDetails = ({ setView, pageContext }) => {
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useContext(AppContext)

  const { userData } = getSignedInUser()

  let { formFields } = pageContext
  formFields = formFields.filter(
    (field) =>
      ![
        "MedGrocer Service Experience",
        "Mental Health Professional (MHP) Feedback",
      ].includes(field?.section)
  )

  let { sectionFormFields, validationSchema } = useMentalHealthFormFields({
    formFields,
  })

  let initialValues = {
    ...generateInitialValues({ fields: formFields }),
    ...state?.feedback,
    ...userData,
  }

  const handleSubmit = (values) => {
    setLoading(true)
    dispatch({ type: "SAVE_FEEDBACK", payload: values })
    setLoading(false)
    setView(FEEDBACK_FORM_VIEW)
  }

  return (
    <Fragment>
      <Message color="primary">
        {personalDetailsMessages.map((message) => (
          <p className="is-size-6">{message}</p>
        ))}
      </Message>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ errors, values, setFieldValue, submitCount }) => (
          <Form>
            {sectionFormFields
              .sort(
                (firstFormField, secondFormField) =>
                  firstFormField.order - secondFormField.order
              )
              .map((section) => (
                <Fragment>
                  {section?.fields.map((field) => {
                    if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            formFields: section?.fields,
                            formField: {
                              ...field,
                              errors: errors,
                              submitCount: submitCount,
                            },
                            values,
                            setFieldValue,
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }
                    return null
                  })}
                </Fragment>
              ))}

            <ActionButtons submit={{ label: "Next", loading }} />
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default PersonalDetails
