import React, { Fragment, useState, useContext } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Section from "elements/Section"
import ActionButtons from "elements/ActionButtons"

import { AppContext } from "../../context/AppContext"
import { generateInitialValues } from "../../services/context"
import { generateFormField } from "elements/Form/services/form"

import { createFeedbackRecord } from "./services/createFeedbackRecord"
import { useMentalHealthFormFields } from "../Enrollment/hooks/useAirtableMHFormFields"

const PERSONAL_DETAILS_VIEW = "PERSONAL_DETAILS_VIEW"

const FeedbackForm = ({ setView, pageContext }) => {
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useContext(AppContext)
  const [notification, setNotification] = useState({
    message: "",
    active: false,
  })

  let { formFields, nextPath } = pageContext
  formFields = formFields.filter((field) =>
    [
      "MedGrocer Service Experience",
      "Mental Health Professional (MHP) Feedback",
    ].includes(field?.section)
  )

  let { sectionFormFields, validationSchema } = useMentalHealthFormFields({
    formFields,
  })

  let initialValues = {
    ...generateInitialValues({ fields: formFields }),
    ...state?.feedback,
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    await createFeedbackRecord({
      values,
      callback: () => {
        setLoading(false)
        navigate(nextPath)
        setNotification({
          active: true,
          type: "success",
          message:
            "Thank you for sending your feedback! Your answer has been recorded.",
        })
      },
      errorCallback: () => {
        setLoading(false)
        setNotification({
          active: true,
          type: "danger",
          message:
            "Something went wrong with sending your feedback. Please try again.",
        })
      },
    })
    setLoading(false)
  }

  return (
    <Fragment>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ errors, values, setFieldValue, submitCount }) => (
          <Form>
            {sectionFormFields
              .sort(
                (firstFormField, secondFormField) =>
                  firstFormField.order - secondFormField.order
              )
              .map((section) => (
                <Fragment>
                  <Section
                    title={section?.section}
                    subtitle={section?.subtitle || ""}
                    id={section?.sectionId || ""}
                  >
                    {section?.fields.map((field) => {
                      if (!field?.referenceAnswer) {
                        return (
                          <Fragment>
                            {generateFormField({
                              formFields: section?.fields,
                              formField: {
                                ...field,
                                errors: errors,
                                submitCount: submitCount,
                              },
                              values,
                              setFieldValue,
                            })}
                            {!!field?.addDividerAfterField && (
                              <hr className="has-background-light" />
                            )}
                          </Fragment>
                        )
                      }
                      return null
                    })}
                  </Section>
                </Fragment>
              ))}

            {notification.active && (
              <div className={`notification is-${notification.type}`}>
                <button
                  className="delete"
                  type="button"
                  onClick={() => {
                    setNotification({ message: "", active: false, type: "" })
                  }}
                />
                {notification.message}
              </div>
            )}

            <ActionButtons
              back={{
                label: "Back",
                callback: () => {
                  setView(PERSONAL_DETAILS_VIEW)
                },
              }}
              submit={{ label: "Continue", loading }}
            />
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default FeedbackForm
