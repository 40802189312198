import * as Yup from "yup"

const REQUIRED_MESSAGE = "This field is required"
const VALID_EMAIL = "Please input a valid email"
const VALID_MOBILE_NUMBER =
  "Please input a valid mobile number in this format: 09991234567"
const yearNow = new Date(Date.now()).getFullYear()

export const getDefaultValidation = ({ field }) => {
  let fieldValidation

  switch (field.type) {
    case "checkbox":
      fieldValidation = Yup.array()
      break

    case "schedule":
      fieldValidation = Yup.object().shape({
        day: Yup.object().shape({
          value: Yup.string().required(REQUIRED_MESSAGE),
        }),
        time: Yup.object().shape({
          value: Yup.string().required(
            "Please indicate when you are free at this chosen day"
          ),
        }),
      })
      break

    case "select":
      fieldValidation = Yup.object().shape({
        value: Yup.string().when("label", {
          is: () => !!field.required,
          then: Yup.string().required(REQUIRED_MESSAGE),
          otherwise: Yup.string(),
        }),
      })
      break

    case "multiselect":
      fieldValidation = Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.string().when("label", {
              is: () => !!field.required,
              then: Yup.string().required(REQUIRED_MESSAGE),
              otherwise: Yup.string(),
            }),
          })
        )
        .min(field?.min || 1, REQUIRED_MESSAGE)
      break

    case "date":
      fieldValidation = Yup.object().shape({
        month: Yup.object().shape({
          value: Yup.string().when("label", {
            is: () => field?.fieldNames?.includes("month") && !!field.required,
            then: Yup.string().required(REQUIRED_MESSAGE),
            otherwise: Yup.string(),
          }),
        }),
        date: Yup.object().shape({
          value: Yup.string().when("label", {
            is: () => field?.fieldNames?.includes("date") && !!field.required,
            then: Yup.string().required(REQUIRED_MESSAGE),
            otherwise: Yup.string(),
          }),
        }),
        year: Yup.number().when("month", {
          is: () => field?.fieldNames?.includes("year") && !!field.required,
          then: Yup.number().when("month", {
            is: () => field?.validation?.includes("Birthday"),
            then: Yup.number()
              .min(1900, "Please input a valid year")
              .max(
                yearNow - parseInt(field.min),
                "You must be at least 18 years old"
              )
              .required(REQUIRED_MESSAGE),
            otherwise: Yup.number()
              .min(1900, "Please input a valid year")
              .max(yearNow, "Please input a valid year")
              .required(REQUIRED_MESSAGE),
          }),
          otherwise: Yup.number(),
        }),
      })
      break

    case "address":
      let shouldInputSite = field.fieldNames.includes(`${field.name}.siteName`)

      fieldValidation = Yup.object().shape({
        city: Yup.object().when("addressType", {
          is: (addressType) =>
            !!field.required &&
            (!shouldInputSite || (shouldInputSite && addressType === "Home")),
          then: Yup.object().shape({
            value: Yup.string().required(REQUIRED_MESSAGE),
          }),
          otherwise: Yup.object().shape({
            value: Yup.string(),
          }),
        }),
        province: Yup.object().when("addressType", {
          is: (addressType) =>
            !!field.required &&
            (!shouldInputSite || (shouldInputSite && addressType === "Home")),
          then: Yup.object().shape({
            value: Yup.string().required(REQUIRED_MESSAGE),
          }),
          otherwise: Yup.object().shape({
            value: Yup.string(),
          }),
        }),
        siteName: Yup.object().when("addressType", {
          is: (addressType) =>
            !!field.required && shouldInputSite && addressType === "Office",
          then: Yup.object().shape({
            value: Yup.string().required(REQUIRED_MESSAGE),
          }),
          otherwise: Yup.object().shape({
            value: Yup.string(),
          }),
        }),
        streetAddress: Yup.string().when("addressType", {
          is: (addressType) =>
            !!field.required &&
            (!shouldInputSite || (shouldInputSite && addressType === "Home")),
          then: Yup.string().required(REQUIRED_MESSAGE),
          otherwise: Yup.string(),
        }),
        addressType: Yup.string().when("value", {
          is: () =>
            field?.fieldNames?.includes("address.addressType") &&
            !!field.required,
          then: Yup.string().required(REQUIRED_MESSAGE),
          otherwise: Yup.string(),
        }),
        notes: Yup.string(),
      })
      break

    case "id":
      fieldValidation = Yup.object().shape({
        front: Yup.object().shape({
          name: Yup.string().required(REQUIRED_MESSAGE),
        }),
        back: Yup.object().shape({
          name: Yup.string().required(REQUIRED_MESSAGE),
        }),
      })
      break

    // case "address":
    //   fieldValidation = Yup.object().shape({
    //     addressType: Yup.string(),
    //     streetAddress: Yup.string(),
    //     province: Yup.object().shape({
    //       label: Yup.string(),
    //       value: Yup.string(),
    //     }),
    //     city: Yup.object().shape({
    //       label: Yup.string(),
    //       value: Yup.string(),
    //     }),
    //   })
    //   break

    case "number":
    case "text":
    case "radio":
    case "textarea":
    default:
      fieldValidation = Yup.string()

      if (field?.validation?.includes("Email"))
        fieldValidation = fieldValidation.concat(
          fieldValidation.email(VALID_EMAIL)
        )

      if (field?.validation?.includes("Mobile Number"))
        fieldValidation = fieldValidation.concat(
          fieldValidation
            .min(11, VALID_MOBILE_NUMBER)
            .max(11, VALID_MOBILE_NUMBER)
            .matches(/^09[0-9]{9}$/, VALID_MOBILE_NUMBER)
        )
      break
  }

  return fieldValidation
}

export const getCustomValidation = ({ field, fieldValidation }) => {
  if (!!field.required) {
    if (!!field.referenceAnswer) {
      let referenceQuestion = field.referenceQuestionName[0]

      fieldValidation = fieldValidation.concat(
        fieldValidation.when(referenceQuestion, {
          is: (referenceAnswer) =>
            referenceAnswer === field?.referenceAnswer ||
            referenceAnswer?.includes(field?.referenceAnswer),
          then: fieldValidation.required(REQUIRED_MESSAGE),
        })
      )
    } else {
      fieldValidation = fieldValidation.concat(
        fieldValidation.required(REQUIRED_MESSAGE)
      )
    }

    if (!!field.minLength) {
      fieldValidation = fieldValidation.concat(
        fieldValidation.min(field.minLength, REQUIRED_MESSAGE)
      )
    }
  }

  return fieldValidation
}
