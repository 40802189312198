import { enrollmentSchema } from "../utils/enrollmentSchema"

let { parseFormField } = require("services/airtable")

export const useMentalHealthFormFields = ({ formFields, hasId = false }) => {
  let validateFormFields = hasId
    ? formFields.concat([{ type: "id", required: true, name: "id" }])
    : formFields

  return {
    sectionFormFields: parseFormField(formFields),
    validationSchema: enrollmentSchema({ fields: validateFormFields }),
  }
}
