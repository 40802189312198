import axios from "axios"

import {
  GATSBY_WEBSITE_URL,
  GATSBY_INTEGROMAT_SEND_FEEDBACK_WEBHOOK_URL,
} from "gatsby-env-variables"

import { getSignedInUser } from "../../Auth/services/user"

export const createFeedbackRecord = async ({
  values,
  callback,
  errorCallback,
}) => {
  const { userData } = getSignedInUser()
  try {
    let response = await axios.post(
      GATSBY_INTEGROMAT_SEND_FEEDBACK_WEBHOOK_URL,
      {
        ...values,
        domain: GATSBY_WEBSITE_URL,
        zendeskId: parseInt(values.zendeskId),
        email: userData.email,
      }
    )
    if (callback) callback()
    return response
  } catch (error) {
    if (errorCallback) errorCallback()
  }
}
